import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Hero } from "../../components/hero"
import PageCTA from "../../components/pageCTA"

export const query = graphql`
  query ProjectTemplateQuery($id: String) {
    file(name: { eq: "bostonian-electric-electrician-working" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    contentfulProjectCollection(id: {eq: $id}) {
      projectName
      projectSlug
      projectDescription {
        raw
      }
      projectImages {
        gatsbyImageData
        description
      }
    }
  }
`
const ProjectTemplate = ({ data }) => {
  const banner = data.file.childImageSharp.gatsbyImageData
  const project = data.contentfulProjectCollection

  return (
    <Layout>
      <Seo title={project.projectName} />
      <Hero
        image={banner}
        header={project.projectName}
        alt="Electrician working on wires"
      />
      <section className="container my-6 mx-auto px-4 font-sans">
        <h2>{project.projectName}</h2>
        {renderRichText(project.projectDescription)}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {project.projectImages.map((image, i) => (
            <div key={i}>
              <GatsbyImage image={image.gatsbyImageData} alt={image.description} />
            </div>
          ))}
        </div>
      </section>
      <PageCTA
        h2="Ready to learn more?"
        buttonText="Get a quote"
        destination="/contact/"
      >
        <p>
          Please{" "}
          <Link
            className="text-yellow-400 hover:underline"
            to="/contact/"
            title="contact"
          >
            contact us
          </Link>{" "}
          for a quote.
        </p>
      </PageCTA>
    </Layout>
  )
}
export default ProjectTemplate
